<template>
  <div>
    <div class="row" v-show="false">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            <b>Data tables</b> The <code>v-data-table</code> component is used
            for displaying tabular data. Features include sorting, searching,
            pagination, inline-editing, header tooltips, and row selection.
            <a
              class="font-weight-bold"
              href="https://vuetifyjs.com/en/components/data-tables"
              target="_blank"
            >
              See documentation.
            </a>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card :loading="doseSaveLoading">
          <template slot="progress">
            <v-overlay absolute class="d-flex flex-column text-center">
              <div>
                <v-progress-circular
                  size="150"
                  color="#1BC5BD "
                  :value="doseSaveLoading"
                  indeterminate
                  ref="savingLoader"
                >
                  <span>{{
                    $t("PAGES.COMPANIES.FORM.MANAGE.SAVING_LOADING")
                  }}</span>
                </v-progress-circular>
              </div>
            </v-overlay>
          </template>
          <v-card-title>
            {{ $t("PAGES.DOSES.EDIT.TITLE") }}
          </v-card-title>
          <b-card-body>
            <v-card-text>
              <v-form ref="edit_dose_form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10" sm="6">
                      <v-text-field
                        v-model="singleItem.title"
                        :rules="requiredRule"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.DOSES.ATTRIBUTES.TITLE')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        v-model="singleItem.weight"
                        :rules="requiredRule"
                        @keypress="isNumber($event)"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.DOSES.ATTRIBUTES.WEIGHT')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :label="$t('PAGES.DOSES.ATTRIBUTES.DESCRIPTION')"
                        v-model="singleItem.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-btn color="success" class="mr-4" @click="validate">
                        {{ $t("COMMON.BUTTON.SAVE") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
          </b-card-body>
        </v-card>
      </div>
    </div>
    <DialogMessage :vars="dialogVars" :dialog.sync="showDialog"></DialogMessage>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import rules from "@/core/helper/rules.helper.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import { CREATE_DOSE_DATA } from "@/core/services/store/doses.module";
import DialogMessage from "@/view/commons/DialogMessage";

export default {
  name: "NewDosesForm",
  data() {
    return {
      showDialog: false,
      dialogVars: {
        area: {},
        message: {
          title: String,
          text: String
        },
        type: String,
        route: false
      },
      valid: true,
      doses_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      requiredRule: [
        (v) => {
          return rules.requiredRules(v);
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "doseLoading",
      "getDoseApiErrors",
      "doseInUse",
      "getDoseSavingSuccess",
      "doseSaveLoading"
    ]),
    singleItem() {
      return this.doseInUse.data != undefined ? this.doseInUse.data : {};
    }
  },
  components: { DialogMessage },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.DOSES.AREA") },
      { title: this.$t("PAGES.DOSES.EDIT.TITLE") }
    ]);
  },
  methods: {
    validate() {
      if (this.$refs.edit_dose_form.validate()) {
        this.save();
      }
    },
    save() {
      var doseToUpdate = Object.assign({}, this.singleItem);
      this.$store.dispatch(CREATE_DOSE_DATA, doseToUpdate);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  watch: {
    getDoseApiErrors: function () {
      this.dialogVars.message.title = this.$t(
        "PAGES.DOSES.COMMON.ERROR_ONSAVE"
      );
      this.dialogVars.message.text = this.getDoseApiErrors;
      this.dialogVars.type = "error";
      this.showDialog = true;
    },
    getDoseSavingSuccess: function () {
      this.dialogVars.message.title = this.$t("COMMON.SUCCESS.TITLE");
      this.dialogVars.message.text = this.getDoseSavingSuccess;
      this.dialogVars.type = "success";
      this.dialogVars.route = "doses_list";
      this.showDialog = true;
      this.singleItem = {};
    }
  }
};
</script>
